import React, { useState, useEffect } from 'react';
import './App.css';
import NavMenu from './components/NavMenu';
import CustomCarousel from './components/CustomCarousel';
import SectionWithBoxes from './components/SectionWithBoxes';
// import SectionPlans from './components/SectionPlans';
import BarraAjuda from './components/BarraAjuda';
import SectionCombos from './components/SectionCombos';
import SectionTv from './components/SectionTv';
import SectionAutoAjuda from './components/SectionAutoAjuda';
import SectionUni from './components/SectionUni';
import Footer from './components/Footer';
import Loader from './components/Loader';
// import { Redirect } from 'react-router-dom';

function App() {
  const [banners, setBanners] = useState([]);
  const [activeImage, setActiveImage] = useState(null);
  const baseUrl = 'https://sites.souuni.com';
  const [loading, setLoading] = useState(true);
  const [redirectToInstagram, setRedirectToInstagram] = useState(false); // Adicionado state para redirecionamento para o Instagram


  useEffect(() => {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer 4757607fc9f0a312e6eaeb6e45aa70cb6ed81e72c129465abe3807cf9a98bc6a096ff4aee1180818e03c732359f069bd8c11c336a0ea2c615419e462445fad170afc63a5350a9c89c5d856ccd4302b9aff958ce259d3f7590c79adca4344dac897c1d45db8ecff8bd1877d31a33da44f48bd37169fcae8b70ee74c4b0997ad33' // Substitua SEU_TOKEN_AQUI pelo seu token de autorização
    };

    // Configurar a opção de cabeçalho para a solicitação da API
    const requestOptions = {
      method: 'GET',
      headers: headers,
    };

    // Fazer a requisição à sua API para obter os banners
    fetch('https://sites.souuni.com/api/banners?filters[base][$eq]=Sistem&populate=*', requestOptions)
      .then(response => response.json())
      .then(data => {
        setBanners(data.data); // Atualize o estado com os banners da API
        setActiveImage(baseUrl + data.data[0].attributes.imagem.data.attributes.url); // Defina o primeiro banner como ativo por padrão
        updateBodyStyles(baseUrl + data.data[0].attributes.imagem.data.attributes.url); // Atualize o estilo do document.body
        setLoading(false);
      })
      .catch(error => {
        console.error('Erro ao buscar banners da API:', error);
        setLoading(false);
      });

          // Verificar se é um dispositivo móvel e definir o redirecionamento para o Instagram
    const isMobile = window.innerWidth <= 768; // Define o breakpoint para dispositivos móveis
    if (isMobile) {
      setRedirectToInstagram(true);
    }
  }, []);

  const handleSlideChange = (index) => {
    if (banners[index]) {
      const imageUrl = baseUrl + banners[index].attributes.imagem.data.attributes.url;
      setActiveImage(imageUrl);
      updateBodyStyles(imageUrl); // Atualize o estilo do document.body quando o slide muda
    }
  };

  const updateBodyStyles = (imageUrl) => {
    document.body.style.backgroundImage = `url(${imageUrl})`;
    document.body.style.background = `linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.9)), url(${imageUrl})`;
    document.body.style.backgroundSize = 'cover';
    document.body.style.backgroundAttachment = 'fixed';
    document.body.style.backgroundRepeat = 'no-repeat';
  };

  if (redirectToInstagram) {
    window.location.href = 'https://www.instagram.com/sistemtelecom/';
    return null; // Você pode retornar null para evitar que o restante do componente seja renderizado
  }

  return (
    <div className="App">
      {loading ? (
        <Loader /> // Renderiza o loader enquanto estiver carregando
      ) : (
        <>
          <NavMenu />
          <CustomCarousel banners={banners.map(banner => baseUrl + banner.attributes.imagem.data.attributes.url)} activeImage={activeImage} onSlideChange={handleSlideChange} />
          <SectionWithBoxes />
          <BarraAjuda />
          <SectionCombos />
          <SectionTv />
          <SectionAutoAjuda />
          <SectionUni />
          <Footer />
        </>
      )}
    </div>
  );
}

export default App;
